import { handleOrdering } from '../utils/functions';
import { getOffsetLimitFromPagination, getQueryString } from '../utils/query';
import { callApi } from './api';

export const SetupTypes = {
  regular: 'Regular',
  ddl: 'DDL',
  external: 'External',
  json: 'JSON'
};

export const FilterRule = {
  Passthrough: 'Passthrough',
  404: '404',
  RedirectUrl: 'Redirect_url',
  InternalRedirect: 'Internal_redirect'
};

export const AutomationRuleMetric = {
  prpm: 'prpm',
  roi: 'roi'
};

export const automationRuleMetricOptions = [
  { value: AutomationRuleMetric.prpm, label: 'PRPM' },
  { value: AutomationRuleMetric.roi, label: 'ROI' }
];

export const comparationSignOptions = [
  { label: 'Less than (<)', value: '<' },
  { label: 'Less than or equal to (<=)', value: '<=' },
  { label: 'Greater than (>)', value: '>' },
  { label: 'Greater than or equal to (>=)', value: '>=' }
];

const columnTitlesAndKeys = {
  id: {
    title: 'Id',
    key: 'id',
    sortingValue: 'id'
  },
  type: {
    title: 'Type',
    key: 'type',
    sortingValue: ''
  },
  title: {
    title: 'Title',
    key: 'title',
    sortingValue: 'title'
  },

  channel: {
    title: 'Channel',
    key: 'channel',
    sortingValue: 'channel'
  },

  company: {
    title: 'Company',
    key: 'company',
    sortingValue: ''
  },

  status: {
    title: 'Status',
    key: 'status',
    sortingValue: ''
  },

  campaignUrl: {
    title: 'Campaign URL',
    key: 'campaignUrl',
    sortingValue: ''
  },

  dateAndTimeCreated: {
    title: 'Date And Time Created',
    key: 'dateAndTimeCreated',
    sortingValue: 'date_created'
  },

  dateAndTimeEdited: {
    title: 'Date And Time Edited',
    key: 'dateAndTimeEdited',
    sortingValue: 'date_edited'
  }
};

export const { id, type, title, channel, company, status, campaignUrl, dateAndTimeCreated, dateAndTimeEdited } = columnTitlesAndKeys;

export const allTableColumns = [
  {
    value: id.key,
    text: id.title
  },
  {
    value: type.key,
    text: type.title
  },
  {
    value: title.key,
    text: title.title
  },
  {
    value: channel.key,
    text: channel.title
  },
  {
    value: company.key,
    text: company.title
  },
  {
    value: status.key,
    text: status.title
  },
  {
    value: campaignUrl.key,
    text: campaignUrl.title
  },
  {
    value: dateAndTimeCreated.key,
    text: dateAndTimeCreated.title
  },
  {
    value: dateAndTimeEdited.key,
    text: dateAndTimeEdited.title
  }
];

class MediaCampaignsService {
  async getCampaignsPaged({
    currentPage,
    pageSize,
    filters,
    searchValue,
    utmCampaign,
    cancelToken,
    fields = 'final_url,channel,company_details,creator_details,date_created,date_edited,id,status,title,type',
    sorting
  }) {
    const ordering = handleOrdering(sorting);

    const { company, identity, creators, forSuspend } = filters;
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      company: company,
      identity: identity,
      search: searchValue,
      utm_campaign: utmCampaign,
      creator__in: creators && creators.join(','),
      for_suspend: !!forSuspend,
      fields,
      ordering: ordering && ordering.join(',')
    });
    return await callApi({ url: `/api/media_campaigns/${query}`, cancelToken });
  }

  async getActivitiesPaged({ campaignId, fields, currentPage, pageSize, filters, search, cancelToken }) {
    const {
      startDate,
      endDate,
      showAllChanges,
      showTargetingChanges,
      showPricingChanges,
      showAutomationRulesChanges,
      countryOrCountryList,
      sourceList
    } = filters;

    const query = getQueryString({
      campaign_id: campaignId,
      fields,
      start_date: startDate?.format('YYYY-MM-DD'),
      end_date: endDate?.format('YYYY-MM-DD'),
      all_content_types: !!showAllChanges,
      targeting_content_types: !!showTargetingChanges,
      pricing_content_types: !!showPricingChanges,
      automation_rules_content_types: !!showAutomationRulesChanges,
      country_or_country_list_id: countryOrCountryList,
      subid_list_id: sourceList,
      search,
      ...getOffsetLimitFromPagination({ currentPage, pageSize })
    });
    return await callApi({ url: `/api/user_activities/get_media_campaign_history/${query}`, cancelToken });
  }

  async getCampaignsInfo(id) {
    return (await callApi({ url: `/api/media_campaigns_formatted/${id}/copy_to_clipboard/` })).data;
  }

  async getCampaigns({ fields = 'id,channel', company } = {}) {
    const query = getQueryString({ fields, company });
    return await callApi({ url: `/api/media_campaigns/${query}` });
  }

  async getFormattedCampaign(id) {
    return await callApi({ url: `/api/media_campaigns_formatted/${id}/` });
  }

  async getIdentities({ fields = 'id,name' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/identities/${query}` });
  }

  async getCampaignTypes() {
    return await callApi({ url: '/api/media_campaigns/types/' });
  }

  async getCampaignStatuses() {
    return await callApi({ url: '/api/media_campaigns/statuses/' });
  }

  async getLinkGenerateMethods() {
    return await callApi({ url: '/api/media_campaigns/get_link_generate_methods/' });
  }

  async getDomains({ fields = 'id,domain_name' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/redirect_subdomains/${query}` });
  }

  async getShortenerDomains({ fields = 'id,address' } = {}) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/short_subdomains/${query}` });
  }

  async getS2sTypes() {
    return await callApi({ url: '/api/media_campaigns/s2s_types/' });
  }

  async getFilterRules() {
    return await callApi({ url: '/api/media_campaigns/filter_actions/' });
  }

  async getExternalUrl({ fields = 'id,name' } = {}) {
    const query = getQueryString({ fields, only_non_company_traffic_back: true });
    return await callApi({ url: `/api/external_urls/${query}` });
  }

  async getRuleTypes() {
    return await callApi({ url: '/api/media_campaigns/rule_types/' });
  }

  async getRuleMetrics() {
    return await callApi({ url: '/api/media_campaigns/rule_metrics/' });
  }

  async getCampaignPatternTypes() {
    return await callApi({ url: '/api/media_campaigns/campaign_pattern_type/' });
  }

  async getProducts() {
    return await callApi({ url: '/api/products/' });
  }

  async getFormatedTargetingList({ id, fields }) {
    const query = getQueryString({ fields });
    return await callApi({ url: `/api/targeting_lists_formatted/${id}${query}` });
  }

  async addMediaCampaign(data) {
    return await callApi({
      url: '/api/media_campaigns_formatted/',
      method: 'POST',
      data
    });
  }

  async activateCampaign(id) {
    return await callApi({
      url: `/api/media_campaigns_formatted/${id}/resume/`,
      method: 'POST'
    });
  }

  async pauseCampaign(id) {
    return await callApi({
      url: `/api/media_campaigns_formatted/${id}/pause/`,
      method: 'POST'
    });
  }

  async suspendCampaigns(data) {
    return await callApi({
      url: '/api/media_campaigns_formatted/suspend/',
      method: 'POST',
      data
    });
  }

  // async unlockPattern(id) {
  //   return await callApi({
  //     url: `/api/media_campaigns_formatted/${id}/unlock_pattern_targeting/`,
  //     method: 'POST'
  //   });
  // }

  // async loadFromGlobalList(id, data) {
  //   return await callApi({
  //     url: `/api/media_campaigns_formatted/${id}/load_from_another_list/`,
  //     method: 'POST',
  //     data
  //   });
  // }

  async editMediaCampaign(id, data) {
    return await callApi({
      url: `/api/media_campaigns_formatted/${id}/`,
      method: 'PUT',
      data
    });
  }

  async deleteCampaign(id) {
    return await callApi({
      url: `/api/media_campaigns_formatted/${id}/`,
      method: 'DELETE'
    });
  }

  async getMagicCheckerDomains() {
    return await callApi({ url: '/api/magic_checker_domains/' });
  }

  async getMagicCheckerBrowsers() {
    return await callApi({ url: '/api/media_campaigns/get_magic_checker_browsers/' });
  }

  async getMagicCheckerDevices() {
    return await callApi({ url: '/api/media_campaigns/get_magic_checker_operating_sytems/' });
  }

  generateOptions = (arr) => {
    return arr.map((option) => {
      return {
        label: option.channel,
        value: option.id
      };
    });
  };
}

export const mediaCampaignsService = new MediaCampaignsService();
