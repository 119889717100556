import {
  BarChartOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Form, Modal, Row, Tabs, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom';

import { Spinner } from '../../../../components/Spinner';
import { mediaCampaignsService } from '../../../../services/media-campaigns';
import { throwErrorIfActiveRuleNotificationsTurnedOff } from '../../../../utils/functions';
import { useBeforeUnload, useEffectTrigger, useForm } from '../../../../utils/hooks';
import { openErrorNotification, openSuccessNotification } from '../../../../utils/notifications';
import { priceListValidator } from '../../../../utils/pricesValidator';
import { redirectTo404 } from '../../../../utils/redirectTo404';
import { showApiErrors } from '../../../../utils/showApiErrors';
import { transformTargetingData } from '../../../../utils/targeting';
import { Activity } from './Activity';
import { Basic } from './Basic';
import { Filters } from './Filters';
import { Redirect } from './Redirect';
import { Rules } from './Rules';
import { S2sAndPricing } from './S2sAndPricing';
import styles from './Tabs.module.css';
import { Targeting } from './Targeting';

const { TabPane } = Tabs;

export const MediaCampaignTabKeys = {
  basic: 'Basic',
  redirect: 'Redirect&MC',
  targeting: 'Targeting',
  pricingAndS2s: 'Pricing&S2S',
  filters: 'Filters',
  rules: 'Rules',
  activity: 'Activity'
};

const { basic, redirect, targeting, pricingAndS2s, filters, rules, activity } = MediaCampaignTabKeys;

const tabOrderCreateAndClone = [basic, redirect, targeting, pricingAndS2s, filters, rules];
const tabOrderEdit = [...tabOrderCreateAndClone, activity];

const renderDeleteAndSaveChangesButtons = (urlId, submitting, onConfirmDeleteCampaign, onConfirmSaveChanges) => {
  return (
    <div className={styles.tabsDeleteAndSaveButton}>
      <Button
        type="primary"
        size="large"
        className={`${styles.btnMarginRight} ${styles.deleteBtn}`}
        style={{ display: Number(urlId) ? 'inline-block' : 'none' }}
        onClick={onConfirmDeleteCampaign}
      >
        <DeleteOutlined />
        Delete
      </Button>

      <Button type="primary" htmlType="submit" size="large" onClick={onConfirmSaveChanges} disabled={submitting}>
        <SaveOutlined />
        Save Changes
      </Button>
    </div>
  );
};

export const MediaCampaignTabs = ({ urlId, queryId, identities, identitiesLoading, onDelete }) => {
  const [loadingTabsData, setLoadingTabsData] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fetchDataTrigger, activateFetchDataTrigger] = useEffectTrigger();
  const [activeTab, setActiveTab] = useState(basic);
  const [tabOrder, setTabOrder] = useState([]);

  const [basicInitialData, setBasicInitialData] = useState(null);
  const [redirectInitialData, setRedirectInitialData] = useState(null);
  const [magicCheckerInitialData, setMagicCheckerInitialData] = useState(null);
  const [pricingAndS2sInitialData, setPricingAndS2sInitialData] = useState(null);
  const [pricingInitialData, setPricingInitialData] = useState(null);

  const [targetingInitialData, setTargetingInitialData] = useState(null);
  const [filtersInitialData, setFiltersInitialData] = useState(null);
  const [rulesInitialData, setRulesInitialData] = useState(null);
  const [campaignsInfo, setCampaignsInfo] = useState('');
  const [wasFormChanged, setWasFormChanged] = useState(false);

  const [campaignType, setCampaignType] = useState();
  const [targetingRadioValue, setTargetingRadioValue] = useState(false);
  const [companyS2sUrl, setCompanyS2sUrl] = useState();

  const [isRevShare, setIsRevShare] = useState(false);

  const [pricingAndS2sFormChangedTrigger, activatePricingAndS2sFormChangedTrigger] = useEffectTrigger();
  const [targetingFormChangedTrigger, activateTargetingFormChangedTrigger] = useEffectTrigger();

  const history = useHistory();
  const location = useLocation();

  const [basicForm] = Form.useForm();
  const [redirectForm] = Form.useForm();
  const [pricingAndS2sForm] = useForm(activatePricingAndS2sFormChangedTrigger);
  const [targetingForm] = useForm(activateTargetingFormChangedTrigger);
  const [filtersForm] = Form.useForm();
  const [rulesForm] = Form.useForm();
  const [magicCheckerForm] = Form.useForm();

  const openListPage = () => {
    history.push('/media-campaigns');
  };

  const isCpmOrCpc = campaignType === 'CPM' || campaignType === 'CPC';
  useEffect(() => {
    setIsRevShare(basicInitialData?.type === 'RS');
  }, [basicInitialData]);

  useEffect(() => {
    let didCancel = false;
    const id = Number(urlId) ? urlId : queryId;

    if (Number(urlId)) {
      setTabOrder(tabOrderEdit);
    } else {
      setTabOrder(tabOrderCreateAndClone);
    }

    const fetchData = async () => {
      if (id) {
        setLoadingTabsData(true);
        try {
          const { basic, redirect, campaign_s2s, prices, campaign_targeting, filters, rules, magic_checker } =
            await mediaCampaignsService.getFormattedCampaign(id);
          if (!didCancel) {
            setBasicInitialData(basic);
            setRedirectInitialData(redirect);
            setMagicCheckerInitialData(magic_checker);
            setPricingAndS2sInitialData(campaign_s2s);
            setPricingInitialData(prices);
            setTargetingInitialData(campaign_targeting);
            setFiltersInitialData(filters);
            setRulesInitialData(rules);
          }
        } catch (e) {
          if (e?.response?.status === 404) {
            redirectTo404(location, history);
          } else {
            showApiErrors(e);
          }
        } finally {
          setLoadingTabsData(false);
        }
      }
    };

    const getCampaignsInfo = async () => {
      try {
        const campaignsInfo = await mediaCampaignsService.getCampaignsInfo(urlId);
        if (!didCancel) {
          setCampaignsInfo(campaignsInfo);
        }
      } catch (e) {
        showApiErrors(e);
      }
    };

    fetchData();

    if (Number(urlId)) {
      getCampaignsInfo();
    }

    setWasFormChanged(false);

    return () => {
      didCancel = true;
    };
  }, [urlId, queryId, fetchDataTrigger, location, history]);

  const transformBasicFormData = (values) => {
    const {
      title,
      partnerTitle,
      company,
      creator,
      prefix,
      channel,
      campaignType,
      tags,
      validateDomain,
      identity,
      uri,
      status,
      linkGenerateMethod,
      linkTimeToLive,
      cid,
      sid,
      additionalParams,
      partnerUrl,
      advancedTargeting,
      lpMacroConfig
    } = values;

    const transformedData = {
      title,
      partner_title: partnerTitle,
      company,
      creator,
      channel: prefix ? prefix.concat(channel) : channel,
      type: campaignType,
      tag: tags || null,
      validate_domain: !!validateDomain,
      identity: identity || null,
      content_uri: uri,
      status,
      link_generate_method: linkGenerateMethod,
      link_ttl: parseInt(linkTimeToLive) || null,
      click_param: cid,
      source_param: sid,
      additional_params: additionalParams,
      partner_url: partnerUrl,
      use_ua_client_hints: advancedTargeting,
      additional_data: lpMacroConfig ? JSON.parse(lpMacroConfig) : null
    };

    return transformedData;
  };

  const transformRedirectFormData = (values) => {
    const {
      useRedirect,
      domain,
      uri,
      useShortener,
      shortenerDomain,
      shortenerUri
      // cid,
      // sid,
      // additionalParams
    } = values;

    let canonicalShortenerUri = shortenerUri;
    if (canonicalShortenerUri && !canonicalShortenerUri.startsWith('/')) {
      canonicalShortenerUri = `/${canonicalShortenerUri}`;
    }

    const transformedData = {
      use_redirect: !!useRedirect,
      redirect_domain: domain || null,
      uri: uri || null,
      short_subdomain: (useShortener && shortenerDomain) || null,
      shortlink_uri: (useShortener && canonicalShortenerUri) || null
      // redirect_click_param: cid || null,
      // redirect_source_param: sid || null,
      // redirect_additional_params: additionalParams || null
    };

    return transformedData;
  };

  const transformS2sFormData = (values) => {
    const { s2sFormList } = values;

    if (s2sFormList) {
      const transformedData = s2sFormList.map((elem) => {
        const { id, isSpentPixel, s2sType, s2sUrl, priceList } = elem;
        const s2sField = {
          s2s_type: s2sType,
          s2s_url: s2sUrl,
          is_spent_pixel: !!isSpentPixel,
          price_list: !isSpentPixel || isCpmOrCpc ? null : transformPricesFormData(priceList)
        };

        if (id && !queryId) {
          s2sField.id = id;
        }

        return s2sField;
      });

      return transformedData;
    }

    return [];
  };

  const transformTargetingFormData = (values) => {
    const { id, globalListRadio, globalTargetingList } = values;

    const isGlobal = !!(globalListRadio && globalTargetingList);

    const transformedData = isGlobal
      ? {
          id,
          global_list: isGlobal
        }
      : transformTargetingData(values, queryId);

    return transformedData;
  };

  const transformFiltersFormData = (values) => {
    const {
      subidSwitch,
      subidLists,
      subidFilterRule,
      subidExternalUrl,
      subidMediaCampaign,
      ipSwitch,
      ipLists,
      ipWhitelists,
      ipFilterRule,
      ipExternalUrl,
      ipMediaCampaign,
      subidId,
      ipId
    } = values;

    const subid = [
      {
        active: !!subidSwitch,
        subid_lists: subidLists || [],
        filter_rule: subidFilterRule || null,
        external_url: subidExternalUrl || null,
        internal_campaign: subidMediaCampaign || null,
        id: subidId
      }
    ];

    const ip = [
      {
        active: !!ipSwitch,
        ip_lists: ipLists || [],
        ip_whitelists: ipWhitelists || [],
        filter_rule: ipFilterRule || null,
        external_url: ipExternalUrl || null,
        internal_campaign: ipMediaCampaign || null,
        id: ipId
      }
    ];

    const transformedData = {
      subid,
      ip
    };

    return transformedData;
  };

  const transformRulesFormData = (values) => {
    const { campaignStartFormList } = values;

    if (campaignStartFormList) {
      const transformedData = {
        campaign_start: campaignStartFormList.map((elem) => {
          const {
            id,
            assignedUsers,
            campaignStartSwitch,
            campaignStartRuleMetric,
            campaignStartNumber,
            campaignStartSlackSwitch,
            campaignStartGmailSwitch
          } = elem;

          throwErrorIfActiveRuleNotificationsTurnedOff(campaignStartSwitch && !(campaignStartSlackSwitch || campaignStartGmailSwitch));

          const rulesField = {
            rule_data: {
              rule_metric: campaignStartRuleMetric,
              number: campaignStartNumber,
              notify_on_slack: campaignStartSlackSwitch,
              notify_on_mail: campaignStartGmailSwitch
            },
            assigned_users: assignedUsers,
            active: campaignStartSwitch
          };

          if (id && !queryId) {
            rulesField.id = id;
          }

          return rulesField;
        })
      };

      return transformedData;
    }

    return {
      campaign_start: []
    };
  };

  const transformPricesFormData = (values) => {
    if (!values) {
      return null;
    }

    const { id, global_list, list_prices } = values;

    const isGlobal = global_list;

    const formatedPriceList = list_prices?.map((item) => {
      const { id, country, price, os, browser, device_type } = item;
      const priceValue = isRevShare ? 0 : price;
      const revSharePercentValue = isRevShare ? price : 0;

      const priceData = {
        country,
        os,
        browser,
        device_type,
        price: priceValue,
        rev_share_percent: revSharePercentValue
      };

      if (id && (isGlobal || !queryId)) {
        priceData.id = id;
      }

      return priceData;
    });
    if (formatedPriceList) {
      const transformedData = {
        global_list,
        // assigned: assigned || [],
        // private: !!privateList,
        list_prices: formatedPriceList
      };

      if (id && (isGlobal || !queryId)) {
        transformedData.id = id;
      }

      return transformedData;
    }

    return null;
  };

  const transformMagicCheckerFormData = (values) => {
    const { useMagicChecker, magicCheckerCampaignType, domain, safePageUrl, safePageAppendParams, countries, devices, browsers } = values;

    const transformedData = {
      use_magic_checker: !!useMagicChecker,
      magic_checker_campaign_type: magicCheckerCampaignType || '',
      magic_checker_domain: domain || null,
      camp_safe_page_url: safePageUrl || '',
      safe_page_append_params: !!safePageAppendParams,
      magic_countries: (countries || []).map((c) => c.toLowerCase()),
      magic_browsers: browsers || [],
      magic_device_os: devices || []
    };

    return transformedData;
  };

  const deleteCampaign = async () => {
    try {
      await mediaCampaignsService.deleteCampaign(urlId);
      openSuccessNotification({ message: 'Campaign successfully deleted!' });
      await onDelete();
    } catch (e) {
      showApiErrors(e);
    }
  };

  const confirmDeleteCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Are you sure you want to delete campaign?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        deleteCampaign();
        openListPage();
      },
      onCancel() {}
    });
  };

  const handleSubmit = async () => {
    let basicFormData;
    let redirectFormData;
    let pricingAndS2sFormData;
    let targetingFormData;
    let filtersFormData;
    let rulesFormData;
    let magicCheckerFormData;

    try {
      setSubmitting(true);
      const invalidTabs = [];

      for (let i = 0; i < tabOrder.length; i++) {
        switch (tabOrder[i]) {
          case basic:
            try {
              basicFormData = transformBasicFormData(await basicForm.validateFields());
            } catch {
              invalidTabs.push(<div>- {basic}</div>);
            }
            break;

          case redirect:
            let redirectTabInvalid = false;
            try {
              redirectFormData = transformRedirectFormData(await redirectForm.validateFields());
            } catch {
              invalidTabs.push(<div>- {redirect}</div>);
              redirectTabInvalid = true;
            }
            try {
              magicCheckerFormData = transformMagicCheckerFormData(await magicCheckerForm.validateFields());
            } catch {
              if (!redirectTabInvalid) {
                invalidTabs.push(<div>- {redirect}</div>);
                redirectTabInvalid = true;
              }
            }
            break;

          case pricingAndS2s:
            try {
              pricingAndS2sFormData = transformS2sFormData(await pricingAndS2sForm.validateFields());
            } catch (e) {
              invalidTabs.push(<div>- {pricingAndS2s}</div>);
            }
            break;

          case targeting:
            try {
              targetingFormData = !targetingRadioValue
                ? transformTargetingFormData(await targetingForm.validateFields())
                : transformTargetingFormData(targetingForm.getFieldsValue(true));
            } catch {
              invalidTabs.push(<div>- {targeting}</div>);
            }
            break;

          case filters:
            try {
              filtersFormData = transformFiltersFormData(await filtersForm.validateFields());
            } catch {
              invalidTabs.push(<div>- {filters}</div>);
            }
            break;

          case rules:
            try {
              rulesFormData = transformRulesFormData(await rulesForm.validateFields());
            } catch {
              invalidTabs.push(<div>- {rules}</div>);
            }
            break;

          case activity:
            break;

          default:
            return;
        }
      }

      if (invalidTabs.length) {
        const message = (
          <>
            <div>Validation failed in tabs:</div>
            {invalidTabs}
          </>
        );

        openErrorNotification({ message, duration: 8 });
        return;
      }
      const pricingCpmCpc = pricingAndS2sForm.getFieldValue('pricingCpmCpc');
      const mediaCampaignsData = {
        basic: basicFormData,
        redirect: redirectFormData,
        magic_checker: magicCheckerFormData,
        campaign_s2s: pricingAndS2sFormData,
        campaign_targeting: targetingFormData,
        filters: filtersFormData,
        rules: rulesFormData,
        prices: isCpmOrCpc ? transformPricesFormData(pricingCpmCpc) : null
      };

      if (isRevShare && (pricingAndS2sFormData.length === 0 || !pricingAndS2sFormData.some((el) => el.is_spent_pixel))) {
        openErrorNotification({ message: 'Please select at least one s2s.' });
        return;
      }

      // NOTE: Must loop through all s2s lists
      let shouldReturn = false;
      for (let s2s of mediaCampaignsData.campaign_s2s) {
        if (s2s && s2s.price_list && !priceListValidator(s2s.price_list.list_prices.map(({ price, id, ...others }) => others))) {
          shouldReturn = true;
          break;
        }
      }
      if (shouldReturn) {
        return;
      }

      if (
        mediaCampaignsData.prices &&
        !priceListValidator(mediaCampaignsData.prices.list_prices.map(({ price, id, ...others }) => others))
      ) {
        return;
      }

      if (Number(urlId)) {
        try {
          await mediaCampaignsService.editMediaCampaign(urlId, mediaCampaignsData);
          openSuccessNotification({ message: 'Media campaign successfully edited!' });
          setWasFormChanged(false);
          activateFetchDataTrigger();
        } catch (e) {
          showApiErrors(e);
        }
      } else {
        try {
          const {
            data: { id }
          } = await mediaCampaignsService.addMediaCampaign(mediaCampaignsData);
          openSuccessNotification({ message: `Media campaign successfully ${queryId ? 'cloned' : 'added'}!` });
          setWasFormChanged(false);
          //wasFormChanged is async, it takes a while until it changes to false, if we exit without Timeout prompt will appear
          setTimeout(() => history.replace(`/media-campaigns/${id}`), 0);
          setActiveTab(basic);
        } catch (e) {
          showApiErrors(e);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };

  const changeTab = (isNext) => {
    for (let i = 0; i < tabOrder.length; i++) {
      if (activeTab === tabOrder[i]) {
        isNext ? setActiveTab(tabOrder[i + 1]) : setActiveTab(tabOrder[i - 1]);
      }
    }
  };

  const handleCompanyChange = useCallback(({ s2s_url }) => {
    const s2sUrl = s2s_url || undefined;
    setCompanyS2sUrl(s2sUrl);
  }, []);

  const handleRadioChange = useCallback((form, setter) => {
    const radioValue = form.getFieldValue('globalListRadio');
    setter(radioValue);
  }, []);

  useEffect(() => {
    handleRadioChange(targetingForm, setTargetingRadioValue);
  }, [targetingForm, targetingFormChangedTrigger, handleRadioChange]);

  const tabPanes = () => {
    return tabOrder.map((key) => {
      let tabPaneChild = null;
      let imgForTab;

      switch (key) {
        case basic:
          tabPaneChild = (
            <Basic
              form={basicForm}
              initialData={basicInitialData}
              allIdentities={identities}
              allIdentitiesLoading={identitiesLoading}
              loadingTabsData={loadingTabsData}
              isClone={queryId}
              onCampaignTypeChange={setCampaignType}
              onCompanyChange={handleCompanyChange}
              onFormChange={setWasFormChanged}
              onRevShareSelected={setIsRevShare}
              isRevShare={isRevShare}
            />
          );
          imgForTab = <img src="/images/basic.svg" width="20" height="20" alt="basic" title="basic" />;
          break;

        case redirect:
          tabPaneChild = (
            <Redirect
              redirectForm={redirectForm}
              redirectInitialData={redirectInitialData}
              magicCheckerForm={magicCheckerForm}
              magicCheckerInitialData={magicCheckerInitialData}
              loadingTabsData={loadingTabsData}
              isClone={queryId}
              onFormChange={setWasFormChanged}
            />
          );
          imgForTab = <img src="/images/redirect.svg" width="20" height="20" alt="redirect" title="redirect" />;
          break;

        case pricingAndS2s:
          tabPaneChild = (
            <S2sAndPricing
              form={pricingAndS2sForm}
              initialData={pricingAndS2sInitialData}
              pricingData={pricingInitialData}
              loadingTabsData={loadingTabsData}
              isCpmOrCpc={isCpmOrCpc}
              isRevShare={isRevShare}
              companyS2sUrl={companyS2sUrl}
              onFormChange={() => {
                setWasFormChanged();
                activatePricingAndS2sFormChangedTrigger();
              }}
              formChangedTrigger={pricingAndS2sFormChangedTrigger}
            />
          );
          imgForTab = <img src="/images/prices.svg" width="20" height="20" alt="prices" title="prices" />;
          break;

        case targeting:
          tabPaneChild = (
            <Targeting
              form={targetingForm}
              initialData={targetingInitialData}
              loadingTabsData={loadingTabsData}
              targetingRadioValue={targetingRadioValue}
              formChangedTrigger={targetingFormChangedTrigger}
              onRadioChange={setTargetingRadioValue}
              onFormChange={(changed) => {
                setWasFormChanged(changed);
                activateTargetingFormChangedTrigger();
              }}
            />
          );
          imgForTab = <img src="/images/targeting.svg" width="20" height="20" alt="targeting" title="targeting" />;
          break;

        case filters:
          tabPaneChild = (
            <Filters
              form={filtersForm}
              initialData={filtersInitialData}
              loadingTabsData={loadingTabsData}
              onFormChange={setWasFormChanged}
              isClone={queryId}
            />
          );
          imgForTab = <img src="/images/filters.svg" width="20" height="20" alt="filters" title="filters" />;
          break;

        case rules:
          tabPaneChild = (
            <Rules
              form={rulesForm}
              initialData={rulesInitialData}
              loadingTabsData={loadingTabsData}
              isClone={queryId}
              onFormChange={setWasFormChanged}
            />
          );
          imgForTab = <img src="/images/rules.svg" width="20" height="20" alt="rules" title="rules" />;
          break;

        case activity:
          tabPaneChild = <Activity campaignId={urlId} />;
          imgForTab = <img src="/images/activity.svg" width="20" height="20" alt="activity" title="activity" />;
          break;

        default:
          return null;
      }

      return (
        <TabPane
          tab={
            <>
              {imgForTab} {key}
            </>
          }
          key={key}
          forceRender
        >
          {tabPaneChild}
        </TabPane>
      );
    });
  };

  const confirmSaveChanges = () => {
    Modal.confirm({
      maskClosable: true,
      title: 'Are you sure you want to save campaign with new data?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        handleSubmit();
      },
      onCancel() {}
    });
  };

  useBeforeUnload(wasFormChanged);

  return (
    <div className={styles.mediaCampaignTabs}>
      <div className={styles.tabsHeadingWrapper}>
        <h1>Campaign</h1>

        <div
          className={styles.closeTabView}
          onClick={() => {
            openListPage();
          }}
        >
          <CloseOutlined />
        </div>
      </div>

      <Prompt when={wasFormChanged} message="" />

      <div style={{ display: submitting ? 'block' : 'none' }}>
        <Spinner />
      </div>

      <div style={{ display: submitting ? 'none' : 'block' }}>
        <Tabs
          activeKey={activeTab}
          animated={false}
          tabBarExtraContent={{
            right: renderDeleteAndSaveChangesButtons(urlId, submitting, confirmDeleteCampaign, confirmSaveChanges)
          }}
          onChange={setActiveTab}
        >
          {tabPanes()}
        </Tabs>
        <Divider />
        <Row>
          <div className={styles.btnsUnderDividerWrapper}>
            <div>
              <Button
                type="primary"
                size="large"
                className={styles.btnMarginRight}
                disabled={activeTab === tabOrder[0]}
                onClick={() => changeTab(false)}
              >
                <LeftOutlined />
                Previous
              </Button>

              <Button
                className={styles.btnMarginRight}
                type="primary"
                size="large"
                disabled={activeTab === tabOrder[tabOrder.length - 1]}
                onClick={() => changeTab(true)}
              >
                Next
                <RightOutlined />
              </Button>
            </div>

            {renderDeleteAndSaveChangesButtons(urlId, submitting, confirmDeleteCampaign, confirmSaveChanges)}
          </div>
        </Row>
        {Number(urlId) ? (
          <Row gutter={32} className={styles.marginTop}>
            <Col>
              <CopyToClipboard
                text={campaignsInfo}
                onCopy={() => {
                  openSuccessNotification({ message: 'Successfully copied to clipboard!', duration: 8 });
                }}
                className="ant-btn ant-btn-primary ant-btn-lg"
              >
                <Tooltip placement="topLeft" title="Click to copy campaign info" mouseLeaveDelay={0.01} arrowPointAtCenter>
                  <CopyOutlined />
                  Copy info
                </Tooltip>
              </CopyToClipboard>
            </Col>

            <Col>
              <Button type="primary" size="large">
                <Link
                  target="_blank"
                  to={`/stubs/channel_stats/?channel=${urlId}&columns=channel,lp_id,product_title,im,dw,pl,pf,spent,earned,profit,roi,rpm,cr`}
                >
                  <BarChartOutlined />
                  Stats
                </Link>
              </Button>
            </Col>
            <Col>
              <Button className={styles.btnMarginRight} type="primary" size="large">
                <Link target="_blank" to={`/media-campaigns/clone?campaignId=${urlId}`}>
                  <CopyOutlined /> Clone
                </Link>
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
